/**
 * Queries used by the subscription details webview (in-app cancellation, etc.)
 */

import { gql } from '@apollo/client';

/**
 * Explicitly query only for the editBillingInfoUrl field.
 * This field results in a Recurly API call so only call it when necessary.
 */
export const currentUserEditBillingInfoUrl = gql`
  query ECOMMERCE_currentUserEditBillingInfoUrl {
    currentUser {
      editBillingInfoUrl
    }
  }
`;

/**
 * Create a Zendesk ticket requesting cancellation.
 */
export const createZendeskCancellationTicket = gql`
  mutation ECOMMERCE_createZendeskCancellationTicket($input: CreateZendeskCancellationTicketInput!) {
    createZendeskCancellationTicket(input: $input) {
      ticketCreated
    }
  }
`;

/**
 * Create a Zendesk ticket requesting replacement.
 */
export const createZendeskReplacementTicket = gql`
  mutation ECOMMERCE_createZendeskReplacementTicket($input: CreateZendeskReplacementTicketInput!) {
    createZendeskReplacementTicket(input: $input) {
      ticketCreated
    }
  }
`;

export const createZendeskTroubleshootingTicket = gql`
  mutation ECOMMERCE_createZendeskTroubleshootingTicket($input: CreateZendeskTroubleshootingTicketInput!) {
    createZendeskTroubleshootingTicket(input: $input) {
      ticketCreated
    }
  }
`;

/**
 * Find all pets with active subscriptions, which is used to selectively show the multidog discount retention page.
 */
export const petsWithActiveSubscriptionQuery = gql`
  query ECOMMERCE_petsWithActiveSubscription {
    petsWithActiveSubscription {
      id
      name
      photos {
        first {
          image {
            fullSize
          }
        }
      }
      device {
        id
        moduleId
        currentCollarModel
        subscription {
          id
          currentTermEndsAt
          subscriptionOption {
            name
            priceInCents
            billingCadence
            displayedRate
            displayedRateUnit
            renewalYears
            renewalMonths
            billingPeriodsPerTerm
            sku
          }
        }
        subscriptionAccessType
        hasSubscriptionOverride
      }
    }
  }
`;

/**
 * Apply multidog discount to current user.
 */
export const applyMultidogDiscountMutation = gql`
  mutation ECOMMERCE_applyMultidogDiscount {
    applyMultidogDiscount {
      success
    }
  }
`;

/**
 * Check if user already has multidog discount.
 */
export const hasMultidogDiscountQuery = gql`
  query ECOMMERCE_hasMultidogDiscount {
    currentUser {
      hasMultidogDiscount
    }
  }
`;

/**
 * Claim a free month for a device.
 */
export const claimFreeMonthMutation = gql`
  mutation ECOMMERCE_claimFreeMonth($input: ClaimFreeMonthInput!) {
    claimFreeMonth(input: $input) {
      success
    }
  }
`;

/**
 * Claim the pause for a month offer for a device.
 */
export const claimPauseMonthMutation = gql`
  mutation ECOMMERCE_claimPauseMonth($input: ClaimPauseMonthInput!) {
    claimPauseMonth(input: $input) {
      success
    }
  }
`;

/**
 * Downgrade an S2 device to monthly plan.
 */
export const downgradeS2Mutation = gql`
  mutation ECOMMERCE_downgradeS2($input: DowngradeSeries2Input!) {
    downgradeSeries2(input: $input) {
      success
    }
  }
`;

/**
 * Get all plans S3 user can downgrade to.
 */
export const getDowngradeablePlans = gql`
  query ECOMMERCE_getAvailableS3DowngradeableSubscriptionOptions(
    $input: AvailableS3DowngradeSubscriptionOptionsInput!
  ) {
    getAvailableS3DowngradeableSubscriptionOptions(input: $input) {
      subscriptionOptions {
        sku
        name
        priceInCents
        displayedRate
        displayedRateUnit
        billingCadence
        renewalMonths
        billingPeriodsPerTerm
        supportPlus
        valueLabel
        savings
        fullRate
        fullRateUnit
        featured
        renewalYears
        activationFeeInCents
        upsellOnly
        buyItMembership
      }
    }
  }
`;

/**
 * Downgrade an S3 device to requested plan.
 */
export const downgradeS3Mutation = gql`
  mutation ECOMMERCE_downgradeS3($input: DowngradeSeries3Input!) {
    downgradeSeries3(input: $input) {
      success
    }
  }
`;

/**
 * Renew subscription for device.
 */
export const renewSubscriptionMutation = gql`
  mutation ECOMMERCE_renewSubscription($input: RenewSubscriptionInput!) {
    renewSubscription(input: $input) {
      success
    }
  }
`;

/**
 * Cancel supplement subscription.
 */
export const cancelSubscriptionMutation = gql`
  mutation ECOMMERCE_cancelSupplementSubscription($input: CancelSupplementSubscriptionInput!) {
    cancelSupplementSubscription(input: $input) {
      success
      reasonForFailure
    }
  }
`;

/**
 * Update supplement subscription.
 */
export const updateSubscriptionMutation = gql`
  mutation ECOMMERCE_updateSupplementSubscription($input: UpdateSupplementSubscriptionInput!) {
    updateSupplementSubscription(input: $input) {
      success
      reasonForFailure
    }
  }
`;

/**
 * Add supplement subscription.
 */
export const addSubscriptionMutation = gql`
  mutation ECOMMERCE_addSupplementSubscription($input: AddSupplementSubscriptionInput!) {
    addSupplementSubscription(input: $input) {
      success
      reasonForFailure
    }
  }
`;

/**
 * Update next ship date for supplements subscription.
 */
export const setSupplementNextShipDateMutation = gql`
  mutation ECOMMERCE_setSupplementNextShipDate($input: SetNextShipmentDateInput!) {
    setSupplementNextShipDate(input: $input) {
      success
      reasonForFailure
    }
  }
`;

/**
 * Set supplement subscription shipping address.
 */
export const setSupplementShippingAddressMutation = gql`
  mutation ECOMMERCE_setSupplementShippingAddress($input: SetShippingAddressInput!) {
    setSupplementShippingAddress(input: $input) {
      success
      reasonForFailure
    }
  }
`;

/**
 * Claim supplement cancellation deterrent to get 33% off next shipment.
 */
export const claimSupplementDiscountDeterrentMutation = gql`
  mutation ECOMMERCE_claimSupplementDiscount($input: ClaimSupplementDiscountInput!) {
    claimSupplementDiscount(input: $input) {
      success
      reasonForFailure
    }
  }
`;
