import React, { useState } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import { TextArea } from '../../../../components/TextArea/TextArea';
import { mkError } from '../../../../lib/util';
import * as gqlTypes from '../../../../types/gql-op-types';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import { useLocation } from 'react-router-dom';
import { BaseInAppCancellationState } from '../util/types';

export default function CancelOtherReason() {
  const { device } = usePurchaseSubscriptionContext();
  const [otherText, setOtherText] = useState('');
  const location = useLocation<BaseInAppCancellationState>();
  const { subscriptionCategory } = location.state;

  const { mutation, loading } = useZendeskCancellationTicket({
    analyticsEvent: events.cancellationReasonOtherSelectedAndClicked,
    selectedCancelReason: gqlTypes.zendeskCancellationReason.OTHER,
    device,
    subscriptionCategory,
    otherText,
  });

  if (loading) {
    return loading;
  }

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  const handleCancel = () => {
    if (!otherText || !device.subscription?.id) {
      throw new Error('Something went wrong.');
    }
    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription.id,
          cancellationReason: gqlTypes.zendeskCancellationReason.OTHER,
          additionalText: otherText,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() =>
            events.cancellationReasonOtherBack({ petId: device.pet?.id, subscriptionCategory })
          }
          closeButtonAnalyticsEvent={() => events.closeFromReasonPage({ petId: device.pet?.id, subscriptionCategory })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Let us know what happened</h1>
          <div className={styles.content}>
            <p>
              Can you please describe your issue? We’ll have a Customer Success Agent contact you to discuss next steps.
            </p>
            <div className={styles.textArea}>
              <TextArea
                onChange={(value) => {
                  value && setOtherText(value);
                }}
                rows={12}
                placeholderText="Type your response here..."
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <CancelConfirmationModal
            onContinue={() => handleCancel()}
            pageName={'Manage Membership > Other Reason'}
            petId={device.pet!.id}
            trigger={
              <Button className={styles.button} disabled={otherText === null || otherText.length === 0}>
                Submit
              </Button>
            }
          />
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
